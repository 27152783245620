// @ts-nocheck
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { getLanguage } from '../translations/language';
import LoadMoreContentComponent from './components/LoadMoreComponent.vue';

const { registerFiltersTo } = require('../utils/VueFilters');
const i18nServices = require('../translations/vuei18nPlugin');

//* --- Instantiate LoadMoreContent VUE App
const LoadMoreApp = createApp(LoadMoreContentComponent, {});

//* Pinia's stores root
LoadMoreApp.use(createPinia());

//* Use Vue Filters
registerFiltersTo(LoadMoreApp);

//* Use i18n Translations
const i18nService = i18nServices[getLanguage()];
LoadMoreApp.use(i18nService);

LoadMoreApp.mount('#loadMoreContent-VueContainer');
