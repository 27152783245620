import unionWith from 'ramda/src/unionWith';
import eqBy from 'ramda/src/eqBy';
import prop from 'ramda/src/prop';
import propOr from 'ramda/src/propOr';
import isEmpty from 'ramda/src/isEmpty';
import Requester from '../../api/Requester';

const defaultInitialState = {
  sectionType: 'regular',
  sectionId: '',
  contentitems: [],
  showSummary: false,
  initialQuatity: 1000,
  isLoading: true,
  isVisible: false,
  areMoreResults: false,
  page: 0,
  offset: 0,
};

const numberOfStories = {
  regular: 25,
  subpub: 25,
};

const CARDS_PER_PAGE = 21;
const CARDS_PER_REQUEST = 21;

export const contentStore = (initialState = defaultInitialState) => ({
  state: () => (initialState),
  actions: {
    //* Mutations
    // ? Fix misspelling : initialQuatity -> initialQuantity
    setInitialState: function ({ isVisible, initialQuatity }) {
      this.isVisible = isVisible;
      this.areMoreResults = isVisible;
      this.isLoading = !isVisible;
      this.initialQuatity = initialQuatity;
      this.offset = 25;  // FE-498 this will make sure that the first api call starts from 26th index
    },
    setFinalState() {
      this.areMoreResults = false;
    },
    setLoading(loadingState) {
      this.isLoading = loadingState;
    },
    increasePage() {
      this.page += 1;
    },
    increaseOffset() {
      this.offset = this.offset + CARDS_PER_REQUEST;
    },
    // ? Fix misspelling :  updateContentitems -> updateContentItems, contentitems -> ContentItems
    updateContentitems(nextItems) {
      this.contentitems = nextItems;
    },

    //* Actions
    initialize: function () {
      this.setInitialState({
        isVisible: this.contentitems.length >= numberOfStories[this.sectionType],
        initialQuatity: numberOfStories[this.sectionType] || 1000,
      });
    },
    finalize() {
      this.setFinalState();
    },
    loadMore: async function () {
      this.setLoading(true);
      const limit = this.initialQuatity + (CARDS_PER_PAGE * (this.page + 1));
      try {
        let nextItems = [...this.contentitems]; // to avoid mutating original array
        const maxOffset = this.offset + (5 * CARDS_PER_REQUEST);
        while (nextItems.length < limit) {
          const result = await Requester.getSectionContent(this.sectionId, CARDS_PER_REQUEST, this.offset);
          const resultItems = propOr([], 'items', result);

          // FE-498 To Detect if no new items were fetched
          const uniqueNewItems = unionWith(eqBy(prop('id')), nextItems, resultItems);
          const isNewDataAdded = uniqueNewItems.length > nextItems.length;
          
          if (!isEmpty(resultItems) && isNewDataAdded ) {
            nextItems = uniqueNewItems;  // Update the list only if new data is added
            this.updateContentitems(nextItems); // Update after each batch of new data
          } else {
            this.setFinalState();
            break;
          }
          this.increaseOffset();
          if (this.offset > maxOffset) {
            throw new Error('Too many request - Possible Cache issue with Public API');
          }
        }
      } catch (error) {
        this.setFinalState();
        this.setLoading(false);
      } finally {
        setTimeout(() => {
          this.increasePage();
          this.setLoading(false);
        }, 500);
      }
    },
  },
});


export default { contentStore };
