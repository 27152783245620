import { defineStore } from 'pinia';
import { contentStore } from './SectionContentStore';
import { buildInitialState } from '../../utils/PrePopulated';

const initialState = buildInitialState();

export const useContentStore = defineStore(
  'content',
  contentStore(initialState.content),
);

export default { useContentStore };
