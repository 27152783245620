const defaultInitialState = {
  lang: 'en',
  dateFormat: 'MMMM DD, YYYY hh:mm A',
  pubTimeZone: 'America/Chicago',
};

export const configStore = (initialState = defaultInitialState) => ({
  state: () => (initialState),
});

export default configStore;
