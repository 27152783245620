import { defineStore } from 'pinia';
import { configStore } from './SectionConfigStore';
import { buildInitialState } from '../../utils/PrePopulated';

const initialState = buildInitialState();

export const useConfigStore = defineStore(
  'config',
  configStore(initialState.sectionConfig),
);

export default { useConfigStore };
