<template>
  <div :class="{ 'update-date': wasModified() }" class="time">
    {{ getLocaleTimestamp() }}
  </div>
</template>

<script>
  import pick from "ramda/src/pick";
  import { useConfigStore } from "../store/StoresCreation";
  import { mapStores } from "pinia";

  export default {
    name: "TimestampComponent",
    props: {
      asset: Object,
    },
    computed: {
      //* Store propagation
      ...mapStores(useConfigStore),

      //* Computed
      locale: function () {
        const pickLocale = pick(["lang", "pubTimeZone"]);
        return pickLocale(this.configStore);
      },
    },
    methods: {
      wasModified() {
        return this.$vueFilters.dateWasModified(this.asset.published_date ,this.asset.modified_date);
      },
      getLocaleTimestamp() {
        return (this.wasModified()) ? `${this.$t('timestamp.updated')} ${this.$vueFilters.localizeDate(this.asset.modified_date, this.locale)}` : this.$vueFilters.localizeDate(this.asset.published_date , this.locale);
      },
    },
  };
</script>