<template>
  <article class="card">
    <figure v-if="image">
      <a :href="card.url" class="image-link-macro">
        <img :src="image" :alt="card.title" loading="lazy" />
        <div class="icon-wrap" v-if="hasVideo">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="38px" height="38px" viewBox="0 0 38 38" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill="#ffffff">
              <path d="M19,38 C8.50658975,38 0,29.4934102 0,19 C0,8.50658975 8.50658975,0 19,0 C29.4934102,0 38,8.50658975 38,19 C38,29.4934102 29.4934102,38 19,38 Z M28.7384506,20.359375 C28.9128177,20.2589041 29,20.1391136 29,20 C29,19.8608864 28.9128177,19.7410959 28.7384506,19.640625 L13.6368159,11.0851818 C13.4624488,10.9847108 13.3127227,10.9731182 13.1876333,11.0504035 C13.0625438,11.1276889 13,11.2668004 13,11.4677422 L13,28.5322578 C13,28.7331996 13.0625438,28.8723111 13.1876333,28.9495965 C13.3127227,29.0268818 13.4624488,29.0152892 13.6368159,28.9148182 L28.7384506,20.359375 Z"/>
            </g>
            </g>
          </svg>
        </div>
      </a>
    </figure>
    <div class="package">
      <a class="kicker h6" v-if="card.home_section" :href="card.home_section.url">{{card.home_section.name}}</a>
      <h3 class="h4">

        <a :href="card.url" v-html="card.title"></a>
      </h3>
      <summary>
        <slot name="summary"></slot>
      </summary>
      <timestamp-component :asset="card"/>
    </div>
  </article>
</template>

<script>
  import { path, pathEq } from "ramda/src";
  import utilsVideo from "../../../video/utils";
  import { useConfigStore } from "../store/StoresCreation";
  import { mapStores } from "pinia";
  import TimestampComponent from "./TimestampComponent.vue";

  export default {
    components: {
      TimestampComponent: TimestampComponent,
    },
    props: {
      card: Object,
    },
    data() {
      return {
        hasVideo: false,
      };
    },
    computed: {
      //* Store propagation
      ...mapStores(useConfigStore),

      //* Computed
      image: function () {
        const resizeImg = (img, size) => {
          let thumb = "";
          if (img.thumbnail) {
            thumb = img.thumbnail.replace(/FREE_1140/, size);
          } else if (img.images) {
            thumb = img.images[size];
          }
          return thumb;
        };

        let image = "";
        if (this.card.asset_type === "videoIngest") {
          image = this.card.thumbnail || this.card.video_thumbnail;
          this.hasVideo = true;
        } else if (this.card.thumbnail) {
          image = resizeImg(this.card, "LANDSCAPE_768");
          if (this.card.lead_media) {
            this.hasVideo = this.card.lead_media.asset_type === "videoIngest";
          }
        } else if (this.card.lead_media) {
          const lead = path(["lead_media"], this.card);
          const leadImg = path(["thumbnail"], lead);

          if (pathEq(["asset_type"], "videoIngest", lead) && leadImg) {
            image = leadImg;
          } else if (lead.thumbnail && !lead.thumbnail.includes(".mp4")) {
            image = resizeImg(lead, "LANDSCAPE_768");
          }
          this.hasVideo = this.card.lead_media.asset_type === "videoIngest";
        } else if (this.card.asset_type === "gallery") {
          image = resizeImg(this.card, "LANDSCAPE_400").replace(/FREE_400/,"LANDSCAPE_768");
        } else {
          image = resizeImg(this.card, "LANDSCAPE_768");
          this.hasVideo = false;
        }
        return image;
      },
    },
  };
</script>
