<template>
  <template v-if="visible">
    <template v-for="card in cards" :key="card.id">
      <card-component :card="card">
        <template v-slot:summary v-if="showSummary && card.story_teaser !== ''">
          {{ card.story_teaser }}
        </template>
      </card-component>
    </template>
  </template>
  <section class="load-more" v-if="moreResults">
    <span v-if="loading" class="glyphicon glyphicon-spinner glyphicon-spin">
    </span>
  </section>
</template>

<script>
  import { useContentStore } from '../store/StoresCreation';
  import { mapStores } from 'pinia';

  import CardComponent from '../../wps-sections/components/TeaserCardComponent.vue'

  const CARDS_PER_PAGE = 21;

  export default {
    name: 'Load More',
    props: {
      areMoreResults: {
        type: Boolean,
        default: false,
      },
    },
    components:{
      CardComponent: CardComponent,
    },
    computed: {
      //* Store propagation
      ...mapStores(useContentStore),

      //* Computed
      cards() {
        const start = this.contentStore.initialQuatity;
        const limit = start + (this.contentStore.page * CARDS_PER_PAGE);
        return this.contentStore.contentitems.slice(start, limit);
      },
      visible() { 
        return this.contentStore.isVisible; 
      },
      moreResults() { return this.contentStore.areMoreResults; },
      loading() { return this.contentStore.isLoading; },
      showSummary() { 
        return this.contentStore.showSummary; 
      },
    },
    
    methods: {
      handleScroll() {
        const threshold = window.innerHeight * 4;
        const scrollPosition = window.scrollY + window.innerHeight;
        const documentHeight = document.body.scrollHeight;

        if (scrollPosition >= documentHeight - threshold) {
          if (!this.loading && this.moreResults) {
            this.contentStore.loadMore();
          } else if (!this.loading) {
            this.contentStore.finalize();
          }
        }
      },
    },

    mounted() {
      this.contentStore.initialize();
      window.addEventListener("scroll", this.handleScroll);
    },
  }
</script>
