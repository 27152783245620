/* eslint-disable max-len, no-underscore-dangle, no-param-reassign */

import isNil from 'ramda/src/isNil';

const isDev = (location.href.includes('//localhost') || location.href.includes('//qa1') || location.href.includes('//qa2')); // eslint-disable-line
const firstLabel = 'background:#0F4491 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff';
const secondLabel = 'background:#373737 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const getAttribution = (customFields, title) => {
  const byline1 = customFields.byline1 ? customFields.byline1 : null;
  const byline2 = customFields.byline2 ? customFields.byline2 : null;
  const byline3 = customFields.byline3 ? customFields.byline3 : null;
  const email1 = customFields.email1 && validateEmail(customFields.email1) ? customFields.email1 : null;
  const email2 = customFields.email2 && validateEmail(customFields.email2) ? customFields.email2 : null;
  const email3 = customFields.email3 && validateEmail(customFields.email3) ? customFields.email3 : null;
  const emailSubject = `?subject=${title}`;

  let attribution = `${email1 ? '<a href="mailto:' + email1 + emailSubject + '">' : ''}${byline1}${email1 ? '<span class="glyphicon glyphicon-envelope"></span></a>' : ''}`;
  if (byline2) {
    attribution += ` | ${email2 ? '<a href="mailto:' + email2 + emailSubject + '">' : ''}${byline2}${email2 ? '<span class="glyphicon glyphicon-envelope"></span></a>' : ''}`;
  }
  if (byline3) {
    attribution += ` | ${email3 ? '<a href="mailto:' + email3 + emailSubject + '">' : ''}${byline3}${email3 ? '<span class="glyphicon glyphicon-envelope"></span></a>' : ''}`;
  }
  return attribution;
};

const getBylines = (customFields) => {
  const { byline1, byline2, byline3 } = customFields;
  const result = [byline1, byline2, byline3];
  return result.filter(byline => !!byline);
};

const consoleLog = function(label) {
  if (isDev) {
    const finalArgs = ['%c MCC-VIDEO %c ' + label + ' ', firstLabel, secondLabel, ...Array.prototype.slice.call(arguments, 1)];
    console.log.apply(null, finalArgs);
  } else {
    console.log.apply(null, arguments);
  }
};

const playVideo = (playerInstance) => {
  const promise = playerInstance.play();
  if (promise !== undefined) {
    promise.then(function () {
      // VIDEOJS play started!
    }).catch(function () {
      // VIDEOJS play was prevented!
    });
  }
};

const filterSources = sources => sources.filter(source => source.src.includes('https:'));

const captionsSetUp = (videoData, playerInstance) => {
  const isCaptionButtonActive = playerInstance.el().querySelector('.vjs-subs-caps-button.vjs-caps-active');
  if (isCaptionButtonActive) {
    isCaptionButtonActive.classList.remove('vjs-caps-active');
  }
  if (videoData.text_tracks.length > 0) {
    playerInstance.one('playing', () => {
      playerInstance.textTracks().tracks_.forEach((track) => {
        track.mode = 'hidden';
      });
      const menuContent = playerInstance.el().querySelector('.vjs-menu-content');
      Array.from(menuContent.children).forEach((item) => {
        item.onclick = function () {
          if (this.classList.contains('vjs-subtitles-menu-item')) {
            this.parentNode.parentNode.parentNode.classList.add('vjs-caps-active');
          } else {
            this.parentNode.parentNode.parentNode.classList.remove('vjs-caps-active');
          }
        };
      });
    });
  }
};

const updateVideoSources = (playerInstance, videoData) => {
  const sources = filterSources(videoData.sources);
  captionsSetUp(videoData, playerInstance);

  if (videoData.projection) {
    if (typeof playerInstance.vr === 'undefined') {
      playerInstance.addClass('mi-vr-enabled');
      playerInstance.bigPlayButton.addClass('vjs-big-vr-play-button');
      import(/* webpackChunkName: "vrPlugin" */ 'videojs-vr')
        .then(() => {
          playerInstance.vr({ projection: 'equirectangular' });
        });
    } else if (!playerInstance.activePlugins_.vr) {
      playerInstance.addClass('mi-vr-enabled');
      playerInstance.bigPlayButton.addClass('vjs-big-vr-play-button');
      playerInstance.vr({ projection: 'equirectangular' });
    }
  } else if (playerInstance.activePlugins_ && playerInstance.activePlugins_.vr) {
    playerInstance.removeClass('mi-vr-enabled');
    playerInstance.bigPlayButton.removeClass('vjs-big-vr-play-button');
    playerInstance.vr().dispose();
  }

  playerInstance.poster(videoData.poster);
  if (playerInstance.allowAds && playerInstance.options_['data-ad-tag'] ) {
    playerInstance.ads.skipLinearAdMode();
    playerInstance.ima.setContentWithAdTag(sources, null, false);
  } else {
    playerInstance.src(sources);
  }

  playVideo(playerInstance);
  return playerInstance;
};

const updateDataCollector = (accountId, videoId, title, player) => {
  const accountID = `&account=${accountId}`;
  const videoID = `&video=${videoId}`;
  const videoName = `&video_name=${title}`;
  const time = `&time=${Date.now()}`;
  const destination = `&destination=${encodeURI(window.location.href)}`;
  const source = `&source=${encodeURI(document.referrer)}`;
  if (player.getChild('DataCollector')) {
    player.one('playing', () => {
      player.getChild('DataCollector').el().firstChild.src = `${accountID}${videoID}${videoName}${time}${destination}${source}`;
    });
  }
};

const filterTextTracks = (video) => {
  if (video.text_tracks.length > 0) {
    video.text_tracks = video.text_tracks.map((track) => {
      if(!isNil(track.sources)){
        track.src = track.sources.find(source => source.src.includes('https:'));
      }
      return track;
    });
  }
};

const setSourcesVideo = (playerInstance, videoData) => {
  filterTextTracks(videoData);
  const sources = filterSources(videoData.sources);
  playerInstance.poster(videoData.poster);
  playerInstance.src(sources);
};

const setAttribution = (element, videoData) => {
  const attribution = getAttribution(videoData.custom_fields, videoData.name);
  if (element.classList.contains('bylines')) {
    element.innerHTML = attribution;
  } else {
    element.innerHTML = `<p class="media-leadtext">${videoData.description}</p><div class="mi-video-bylines">By ${attribution}</div>`;
  }
};



export default {
  playVideo,
  consoleLog,
  getAttribution,
  getBylines,
  validateEmail,
  filterSources,
  updateVideoSources,
  isDev,
  updateDataCollector,
  captionsSetUp,
  setSourcesVideo,
  setAttribution,
};
